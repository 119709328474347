<template>
  <article id="wrap">
    <AppHeader theme="green">すてきなせかい</AppHeader>
    <section class="contents">
      <div class="contentInner pb_80">
        <h3 class="text-center">あなたが興味のあるマスターを選ぼう！</h3>
        <ArtistListLink v-for="artist in artists" :artist="artist" :key="`artist-${artist.id}`"></ArtistListLink>
      </div><!--.contentInner-->
    </section><!--.contents-->
    <AppFooter fixed></AppFooter>
  </article>
</template>

<script>
import AppHeader from '@/components/AppHeader';
import AppFooter from '@/components/AppFooter';
import ArtistListLink from '@/components/ArtistListLink';
export default {
  name: 'ArtistList',
  components: {AppHeader, AppFooter, ArtistListLink},
  data () {
    return {
      artists: [],
    }
  },
  mounted() {
    let talk_room_id = localStorage.getItem('talk_room_uuid');
    let payment_plan_id = localStorage.getItem('payment_plan_id');//これ
    console.log('payment_plan_id='.payment_plan_id)
    if(talk_room_id && payment_plan_id) {
      this.$router.push({ 
        name: 'subscription', 
        params: { 
          talk_room: talk_room_id, 
          payment_plan: payment_plan_id 
        } 
      });
    }
  },
  created () {
    this.initialize();
  },
  methods: {
    async initialize () {
      const response = await this.$http.get('artists');
      this.artists = response.data.data;
    }
  }
}
</script>

<style scoped>
.text-center {
  text-align: center;
}
</style>
