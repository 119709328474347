<template>
  <div class="white_bg_area">

    <div class="artist-detail_photo"><img v-if="image" :src="image" alt="マスター選択画像"></div>
    <div class="bold" style="text-align: center; margin-top: 10px;">{{ payment_plan.name }}</div>

    <ul class="artist-detail_info">
      <li v-if="(displayLimitUserRatio && payment_plan.limit_user && payment_plan.user_count / payment_plan.limit_user >= displayLimitUserRatio) || (!payment_plan.limit_user && payment_plan.user_count >= 300)">
        <div class="bold">
          {{ payment_plan.user_count }}
          <span v-if="payment_plan.limit_user !== null">/ {{ payment_plan.limit_user }}</span>
        </div>
        <div class="txt_9">参加者</div>
      </li>
      <li>
        <div class="bold">{{ payment_plan.price | currency }}</div>
        <div class="txt_9">月額</div>
      </li>
    </ul>

    <Nl2br tag="div" class-name="txt_12" :text="payment_plan.comment"></Nl2br>

    <div class="bt_form mtb_20">
      <router-link v-if="!isJoined && !isFull && result" :to="{name:'subscription', params: {talk_room: talk_room.uuid, payment_plan: payment_plan.id}}">入会する</router-link>
      <router-link v-if="!result" :to="{name:'login'}" @click.native="saveToSession"><div class="under-text">登録またはログイン</div><div>して入会する</div></router-link>
      <span v-if="isJoined">入会済み</span>
      <span v-if="isFull">入会参加定員に達しました</span>
    </div>

  </div><!-- .white_bg_area -->
</template>

<script>
import Nl2br from 'vue-nl2br'
import moment from 'moment';
export default {
  name: 'TalkRoomBoard',
  components: {Nl2br},
  props: {
    talk_room: Object,
    payment_plan: Object,
  },
  data() {
    return {
      displayLimitUserRatio: 0.3, // 参加者を設定上限人数の30％に到達するまで非表示
      result : false,
    }
  },
  filters: {
  },
  computed: {
    image () {
      return `${process.env.VUE_APP_API_ROOT}../${this.payment_plan.image}`;
    },
    isJoined () {
      if (!this.$store.state.loginUser) return false;
      if (!this.$store.state.loginUser.talk_rooms) return false;
      // const user_talk_room = this.$store.state.loginUser.talk_rooms.find(talk_room => talk_room.id == this.talk_room.id);
      // if (!user_talk_room.subscription.last_paid_at) return false;
      // すでにトークルームに入会していたらプランに関係なく入会済みにする
      return !!this.$store.state.loginUser.talk_rooms.find(talk_room => talk_room.id === this.talk_room.id);
    },
    isFull () {
      return this.payment_plan.limit_user && this.payment_plan.user_count >= this.payment_plan.limit_user;
    },
    /**
     * 期限切れフラグ
     * @returns {boolean}
     */
    isExpired () {
      const user_talk_room = this.$store.state.loginUser.talk_rooms.find(talk_room => talk_room.id == this.talk_room.id);
      // this.$store.state.loginUser.talk_rooms.forEach((room, index) => {
      //   console.log(room.subscription.last_paid_at);
      // });
      if (!this.$store.state.loginUser.talk_rooms[0].subscription.last_paid_at) return true;
      if (!user_talk_room || !user_talk_room.subscription) return false;
      if (!user_talk_room.subscription.last_paid_at) return true;
      const now = moment();
      const aMonthAgo = now.subtract(1, 'M');
      return aMonthAgo.isAfter(moment(user_talk_room.subscription.last_paid_at));
    }
  },
  created () {
    localStorage.removeItem('payment_plan_id');
    this.result = this.getUserInfo();
    console.log(this.isExpired)
    if (this.isExpired) {
      const user_talk_room = this.$store.state.loginUser.talk_rooms.find(talk_room => talk_room.id == this.talk_room.id);
      const subscription = user_talk_room.subscription;
      // 入会データのタイプと同じ決済を継続する
      const payment_plan = this.talk_room.payment_plans.find(payment_plan => payment_plan.type === subscription.type);
      localStorage.setItem('talk_room_id', this.talk_room.id);
      localStorage.setItem('talk_room_uuid', user_talk_room.uuid);
      localStorage.setItem('payment_plan_id', this.payment_plan.id);
      this.$router.push({name: 'subscription', params: {talk_room: user_talk_room.uuid, payment_plan: payment_plan.id}});
    }
  },
  methods: {
    getUserInfo() {
      const is_login_user = this.$store.state.loginUser.id;
      if(is_login_user == null) {
        return false;
      } else {
        return true;
      }
    },
    saveToSession() {
      localStorage.setItem('talk_room_id', this.talk_room.id);
      localStorage.setItem('talk_room_uuid', this.user_talk_room.uuid);
      localStorage.setItem('payment_plan_id', this.payment_plan.id);
    }
  }
}
</script>

<style lang="scss" scoped>
.under-text {
  margin-bottom: 5px;
}
.white_bg_area {
  background-color: none!important;
}
</style>
