<template>
  <ul class="myartist_area" :class="{'area_link': mode==='selector' && !hasJoin || isExpired}" @click="onClick">
    <li>
      <div class="myartist_photo" v-if="talk_room.artist.image">
        <img :src="`${rootUrl}${talk_room.artist.image}`" alt="マスター選択画像">
      </div>
    </li>
    <li>
      <div class="bold talk_room_name">{{ talk_room.name }}</div>
      <div class="artist_date">最終トーク：
        <span v-if="lastMessage">{{ lastMessage.send_at }}</span>
        <span v-else>----</span>
      </div>
      <div class="artist_info" v-if="!isMaster">ファン歴：
        <span>{{ elapsed_time }}</span>
      </div>
      <div class="artist_info artist_info_master" v-else>
        ★本人のトークルーム <span><a :href="`${apiUrl}talk/${talk_room.artist.uuid}`">{{ `${apiUrl}talk/${talk_room.artist.uuid}` }}</a></span>
      </div>
      <div class="artist_info" v-if="mode==='holder' && !isMaster">
        <span v-if="talk_room.subscription.type === 'normal' && talk_room.payment_plans.some(plan => plan.type === 'premium')" class="normal-mark" @click.stop="onClickUpgrade"><a>プレミアム登録</a></span>
        <span v-if="talk_room.subscription.type === 'premium'" class="premium-mark">プレミアム会員</span>
      </div>
    </li>
    <template v-if="mode==='holder'">
      <li v-if="isExpired">
        <div class="bt_delete"><a @click.stop="onClickReSubscribe">更新</a></div>
      </li>
    </template>
    <template v-if="mode==='selector'">
      <li v-if="!hasJoin || isExpired">
        <div class="link_arrow"><img src="@/assets/img/mypage/link_arrow.png" alt="矢印画像"></div>
      </li>
      <li v-if="hasJoin && !isExpired">
        <p class="has_join">入会中</p>
      </li>
    </template>
    <modal class="modal-upgrade" name="modal-upgrade" :adaptive="true"
           height="auto" width="95%" :maxWidth="600" :styles="{borderRadius:'10px'}">
      <div class="modal-window">
        <div class="modal-content">
          <div>
            プレミアムプランに入るには、まず通常プランを解約してから加入してね！！
          </div>
          <div class="bt_yes_no">
            <div class="bt_form">
              <!-- <MypageTalkRoomUnsubscribe :talkRoom="talk_room" :noTitle="true" /> -->
            </div>
            <div class="bt_cancel"><a @click.stop="$modal.hide('modal-upgrade')">OK</a></div>
          </div>
        </div>           
      </div>           
    </modal>                
  </ul><!-- .myartist_area -->
</template>

<script>
import moment from 'moment';
import axios from 'axios';
// import MypageTalkRoomUnsubscribe from '@/components/MypageTalkRoomUnsubscribe';
export default {
  name: 'TalkRoomLink',
  // components: {MypageTalkRoomUnsubscribe},
  props: {
    talk_room: Object,
    mode: {
      validator: function (value) {
        return ['holder', 'selector'].indexOf(value) !== -1;
      },
    },
  },
  data() {
    return {
      lastMessage: null
    }
  },
  filters: {
    formatDate (val) {
      return moment(val).format('YYYY/MM/DD HH:mm');
    }
  },
  computed: {
    rootUrl () {
      return `${process.env.VUE_APP_API_ROOT}../`;
    },
    hasJoin () {
      const index = this.$store.state.loginUser.talk_rooms.findIndex(talk_room => {
        return talk_room.id === this.talk_room.id
      });
      return index > -1;
    },
    apiUrl() {
      return process.env.VUE_APP_URL;
    },
    /**
     * 期限切れフラグ
     * @returns {boolean}
     */
    isExpired () {
      if (this.isMaster) return false;
      const user_talk_room = this.$store.state.loginUser.talk_rooms.find(talk_room => talk_room.id == this.talk_room.id);
      if (!user_talk_room.subscription) return false;
      if (!user_talk_room.subscription.last_paid_at) return true;
      const now = moment();
      const aMonthAgo = now.subtract(1, 'M');
      return aMonthAgo.isAfter(moment(user_talk_room.subscription.last_paid_at));
    },
    isMaster () {
      if(this.user.artist_id === this.talk_room.artist_id) {
        this.$store.commit('setTalkRoomId', this.talk_room.payment_plans[0]['talk_room_id']);
      }
      return this.user.artist_id === this.talk_room.artist_id;
    },
    hasPremiumPaymentPlan () {
      return !!this.talk_room.payment_plans.find(plan => plan.type === 'premium');
    },
    user () {
      return this.$store.state.loginUser;
    },
    subscription () {
      const talk_room = this.user.talk_rooms.find(room => room.id === this.talk_room.id);
      if (!talk_room) {
        //  退会済み
        return false;
      }
      return talk_room.subscription;
    },
    elapsed_time () {
      const from = moment(this.subscription.authorized_at);
      const now = moment();
      const year = now.diff(from, 'year');
      const month = now.subtract(year, 'year').diff(from, 'month');
      const day = now.subtract(month, 'month').diff(from, 'day');
      if (year && month) {
        return `${year}年${month}ヵ月`;
      } else if (year && !month) {
        return `${year}年`;
      } else if (month && day) {
        return `${month}ヵ月${day}日`;
      } else if (month && !day) {
        return `${month}ヵ月`;
      } else {
        if(day === 0) {
          return '-----------'
        }
        return `${day}日`;
      }
    },    
  },
  created () {
    this.initialize();
  },
  methods: {
    async initialize () {
      const lastMessage = await axios.get(
          `https://${process.env.VUE_APP_CHAT_SERVER}/talk_rooms/${this.talk_room.id}/last`
      );
      if (lastMessage.data.message) {
        this.lastMessage = lastMessage.data.message;
      }
    },
    onClick () {
      if (this.mode === 'holder') { // マイページ
        // return;
        if (this.isExpired) {
          this.$router.push({name: 'artist-detail', params:{ artist: this.talk_room.artist.uuid}});
        } else {
          this.$router.push({name: 'talk', params: {'talk_room': this.talk_room.uuid}});
        }
      } else if (this.mode === 'selector') {  // マスター選択
        if (this.hasJoin && !this.isExpired) return;
        this.$router.push({name: 'artist-detail', params:{ artist: this.talk_room.artist.uuid}});
      }
    },
    onClickReSubscribe () {
      this.$router.push({name: 'artist-detail', params:{ artist: this.talk_room.artist.uuid}});
    },
    onClickUpgrade() {
      this.$modal.show('modal-upgrade');
    },    
  }
}
</script>

<style lang="scss" scoped>
ul.area_link {
  cursor: pointer;
}
ul.myartist_area {
  background-color: #fff;
  // border-radius: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  border-bottom: solid 0.5px gray;
  border-top: solid 0.5px gray;
}
ul.myartist_area li .myartist_photo {
  width: 85px;
  height: 100%;
  min-height: 85px;
  overflow: hidden;
}
ul.myartist_area li .myartist_photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}
ul.myartist_area li:nth-child(2) {
  flex-grow: 1;
  padding: 10px 0 5px 10px;
}
ul.myartist_area li:nth-child(3) {
  align-self: center;
  padding: 10px;
}
#wrap ul.myartist_area li .bt_form a ,
#wrap ul.myartist_area li .bt_delete a {
  font-size: 12rem;
  padding: 8px;
  width: 100px;
}
#wrap ul.myartist_area li .bt_small_text a {
  font-size: 10rem;
  padding: 8px 8px;
}

ul.myartist_area li .artist_date {
  font-size: 8rem;
  color: #929292;
  margin-top: 5px;
}

ul.myartist_area li .artist_info {
  font-size: 8rem;
  color: #929292;
  margin-top: 5px;
}

ul.myartist_area li .artist_info_master {
  color: black;
  font-weight: bold;
}
ul.myartist_area .talk_room_name {
  font-size: 13px;
}
.normal-mark {
  background-color: blue;
  box-shadow: 2px 2px 2px gray;
  padding: 3px;
  border-radius: 5px;
  color: white!important;
}
.normal-mark a:hover {
    color: white!important;
}

.premium-mark {
  background-color: gold;
  // box-shadow: 2px 2px 2px gray;
  padding: 3px;
  border-radius: 5px;
}

li p.has_join {
  background-color: white;
  width: 80px;
  padding: 8px 12px;
  border-radius: 30px;
  text-align: center;
  border: 1.5px solid #000000; 
  font-weight:bold;
}
</style>
